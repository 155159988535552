<div fxLayout='column' fxLayoutAlign="start stretch" class="column-layout">

    <div fxFlex="100" fxLayout.xs='column' fxLayout.sm="column" fxLayout.sm="row" id="telephone" class="text-color-white padding-layout">
        
        <div class="desktop-layout-asset">

            <div fxHide.xs fxHide.sm class="text-align-left desktop-layout">
                <div class="text-body-0 text-color-white main-phone-message">Per tutta la durata della rilevazione sarà a tua disposizione anche un Numero Verde al quale rivolgersi in caso di dubbi o di problemi tecnici.</div>
                <div class="text-body-2 text-color-white main-phone-message">Il numero verde è attivo dal lunedì al venerdì, dalle 10 alle 13 e dalle 15 alle 17.</div>
            </div>
    
            <div fxFlex.md="30" fxFlex.lg="30" fxFlex.xl="30" fxHide.xs fxHide.sm class="image-logo desktop-layout">
                <img src="/assets/images/telephone-logo.png" width="300px">
            </div>
        </div>

        <div class="mobile-layout-asset">
            <div class="text-align-center">
                <div class="text-body-0 text-color-white main-phone-message">Per tutta la durata della rilevazione sarà a tua disposizione anche un Numero Verde al quale rivolgersi in caso di dubbi o di problemi tecnici.</div>
                <div id="sub-phone-message" class="text-body-2 text-color-white main-phone-message">Il numero verde è attivo dal lunedì al venerdì, dalle 10 alle 13 e dalle 15 alle 17.</div>
            </div>
    
            <div class="image-logo">
                <img src="/assets/images/telephone-logo.png" width="80%">
            </div>
        </div>
    </div>

    <div fxFlex="100" id="footer" class="padding-layout">

        <div fxFlex.xs="100" fxFlex.sm="100" fxLayout='column' class="text-align-left" fxHide.md fxHide.lg fxHide.xl>
            <a [routerLink]="['/privacy-cookie']" class="text-align-center">Privacy e Cookie Policy</a>
            <a [routerLink]="['/partecipation-condition']" class="text-align-center">Condizioni di partecipazione</a>
            <br>
            <p class="text-align-center">© {{currentYear}} DoxaeXpression è un'iniziativa di MobileXpression con la collaborazione di Doxa</p>
        </div>

        <div fxHide.xs fxHide.sm>
            <div class="desktop-setting">
                <div id="footer-copy" class="desktop-footer-item text-align-left">© {{currentYear}} DoxaeXpression è un'iniziativa di MobileXpression con la collaborazione di Doxa</div>
                <div id="footer-menu" class="desktop-footer-item text-align-right">
                    <a [routerLink]="['/privacy-cookie']">Privacy e Cookie Policy</a> |
                    <a [routerLink]="['/partecipation-condition']"> Condizioni di partecipazione</a>
                </div>
            </div>
        </div>

    </div>

</div>