import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partecipation-condition',
  templateUrl: './partecipation-condition.component.html',
  styleUrls: ['./partecipation-condition.component.scss']
})
export class PartecipationConditionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
