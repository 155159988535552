import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FaqComponent } from './faq/faq.component';
import { PartecipationConditionComponent } from './partecipation-condition/partecipation-condition.component';
import { PrivacyCookieComponent } from './privacy-cookie/privacy-cookie.component';

const routes: Routes = [
  { path: 'home', component: DashboardComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'privacy-cookie', component: PrivacyCookieComponent },
  { path: 'partecipation-condition', component: PartecipationConditionComponent },
  { path: '**', component: DashboardComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
