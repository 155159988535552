import { Injectable } from '@angular/core';
import { NgxCookiebotService } from '@halloverden/ngx-cookiebot';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public readonly PRE_REQUEST_PHASE: string = 'pre';
  public readonly CONFIRM_REQUEST_PHASE: string = 'confirm';

  private baseUrl: string;

  constructor() {
    this.baseUrl = '';

    // this._cookieBotService.onServiceReady$.pipe(
    //   filter((ready: boolean) => {
    //     return ready;
    //   })
    // ).subscribe(() => {
    //   // this._cookieBotService.cookiebot is available
    // });
  }

  public setBaseUrl(baseUrl: string): ApiService {
    this.baseUrl = baseUrl;
    return this;
  }

  public prizeRequest(phone: string, email: string, phase: string): Promise<any> {
    let task = 'iscrizione';
    if (phase === this.CONFIRM_REQUEST_PHASE) task = 'iscrizione_confirm';

    return this.postData(this.baseUrl + '/_include/ajax.php', {
      task: task,
      telefono: phone,
      email: email,
      check: 'S'
    });
  }

  public infoRequest(email: string, phone: string, subject: string, message: string): Promise<any> {
    return this.postData(this.baseUrl + '/_include/ajax.php', {
      task: 'invio_mail',
      email: email,
      cellulare: phone,
      argomento: subject,
      messaggio: message
    });
  }

  /**
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
   * @param url
   * @param data 
   * @returns 
   */
  protected async postData(url: string, data: any): Promise<any> {
    let formBody: Array<string> = new Array<string>();
    let keys = Object.keys(data);
    keys.map((key) => {
      formBody.push(key+'='+encodeURIComponent(data[key].toString()));
    });
    try {
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: formBody.join('&')
      });
      console.log(response);
      return response.json();
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
