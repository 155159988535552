import { Component } from '@angular/core';
import { Breakpoints, BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, shareReplay } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { NgxCookiebotService } from '@halloverden/ngx-cookiebot';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  private api: ApiService;

  public email: string = '';
  public phone: string = '';
  public prizePrivacy: boolean = false;

  public infoEmail: string = '';
  public infoPhone: string = '';
  public infoSubject: string = '';
  public infoMessage: string = '';
  public infoPrivacy: boolean = false;

  ngOnInit() { }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 960px)']).pipe(
    map(result => !result.matches),
    shareReplay()
  );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private _cookieBotService: NgxCookiebotService
  ) {
    this._cookieBotService.onServiceReady$.pipe(
      filter((ready: boolean) => {
        return ready;
      })
    ).subscribe(() => {
      // this._cookieBotService.cookiebot is available
    });
    
    this.api = new ApiService().setBaseUrl('https://www.doxaexpression.it');       // TODO add enviroment variables
  }

  public requestPrize() {
    // If the user has not accepted the privacy policy
    if (!this.prizePrivacy) {
      this.openMessageDialog('Per richiedere il premio devi prima accettare l\'Informativa privacy di Doxa.');
      return;
    }

    if (this.phone == '' || this.phone.length < 10) {
      this.openMessageDialog('Attenzione! Specificare un numero di cellulare valido!');
      return;
    }

    if (this.email == "" || !this.isEmail(this.email)) {
      this.openMessageDialog('Attenzione! Inserire un indirizzo e-mail valido!');
      return;
    }

    // (else) if the user has accepted start the prize request flow
    this.api.prizeRequest(this.phone, this.email, this.api.PRE_REQUEST_PHASE)
      .then((result: any) => {
        if (result.error === 'S') {
          if (result.msg) {
            this.openMessageDialog(result.msg);
          } else {
            this.openMessageDialog('Si è verificato un\'errore imprevisto. Si prega di riprovare più tardi.');
          }
        } else {
          this.openMessageDialog(result.msg, true)
            .then((result: boolean) => {
              if (result === true) {
                this.api.prizeRequest(this.phone, this.email, this.api.CONFIRM_REQUEST_PHASE)
                  .then((result2: any) => {
                    if (result2.error === 'S') {
                      if (result2.msg) {
                        this.openMessageDialog(result2.msg);
                      } else {
                        this.openMessageDialog('Si è verificato un\'errore imprevisto. Si prega di riprovare più tardi.');
                      }
                    } else {
                      if (result2.msg) {
                        this.openMessageDialog(result2.msg);
                      } else {
                        this.openMessageDialog('La tua richiesta premio è stata registrata correttamente.');
                      }
                    }
                  })
                  .catch((error2: any) => {
                    console.error(error2);
                    this.openMessageDialog('Si è verificato un\'errore imprevisto. Si prega di riprovare più tardi.');
                  });
              }
            })
            .catch((err) => {
              console.error('Error retrieving user confirmation');
              console.error(err);
            });
        }
      })
      .catch((error: any) => {
        console.error(error);
        this.openMessageDialog('Si è verificato un\'errore imprevisto. Si prega di riprovare più tardi.');
      });
  }

  public requestInfo() {

    // If the user has not accepted the privacy policy
    if (!this.infoPrivacy) {
      this.openMessageDialog('Per inviare una richiesta devi prima accettare l\'Informativa privacy di Doxa.');
      return;
    }

    if (this.infoEmail == "" || !this.isEmail(this.infoEmail)) {
      this.openMessageDialog('Attenzione! Inserire un indirizzo e-mail valido!');
      return;
    }

    if (this.infoPhone == '' || this.infoPhone.length < 10) {
      this.openMessageDialog('Attenzione! Specificare un numero di cellulare valido!');
      return;
    }

    if (this.infoSubject === "") {
      this.openMessageDialog('Attenzione! Seleziona un argomento.');
      return;
    }

    if (this.infoMessage.length > 1000) {
      this.openMessageDialog('Attenzione! Hai inserito un testo più lungo di 1000 caratteri.');
      return;
    } else if (this.infoMessage.length < 5) {
      this.openMessageDialog('Attenzione! Inserisci almeno 5 caratteri.');
      return;
    }

    this.api.infoRequest(this.infoEmail, this.infoPhone, this.infoSubject, this.infoMessage)
      .then((result: any) => {
        if (result.res && result.res === 'OK') {
          this.openMessageDialog('Messaggio inviato con successo.');
        } else {
          if (result.msg) {
            this.openMessageDialog(result.msg);
          } else {
            this.openMessageDialog('Attenzione! Si è verificato un errore inatteso in fase di invio.');
          }
        }
      })
      .catch((error: any) => {
        console.error(error);
        this.openMessageDialog('Attenzione! Si è verificato un errore inatteso in fase di invio.');
      });
  }

  openMessageDialog(message: string, isConfirm: boolean = false): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let closeLabel = 'Ok';
      let confirmLabel = '';
      if (isConfirm) {
        closeLabel = 'Annulla';
        confirmLabel = 'Ok'
      }
      let parsedMessageContainer: any = new DOMParser().parseFromString(message, 'text/html');
      let parsedMessage: string = parsedMessageContainer.body.textContent || "";

      const dialogRef = this.dialog.open(DialogMessageComponent, {
        data: {
          message: parsedMessage,
          closeLabel: closeLabel,
          requestConfirm: isConfirm,
          confirmLabel: confirmLabel
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result:`);
        console.log(result);
        let returnState: boolean = result === true ? true : false;
        resolve(returnState);
      });
    });
  }

  /**
   * @note method based on original doxaexpression function to check an email (@see /app/backend/_static/js/dm.js)
   * Controlla se un indirizzo email è corretto
   * 
   * @param sDato indirizzo email da controllare
   * @returns {Boolean} true se il campo è un'email, false altrimenti
   */
  protected isEmail(sDato: string) {
    if (sDato != "") {
      let s: string = sDato;
      let localPartfilter1 = /^[^<>()\[\]\x5C.,;:@" ]+(\.[^<>()\[\]\x5C.,;:@" ]+)*@$/;
      let localPartfilter2 = /^"[^\r\n]+"@$/;
      let domainfilter = /^([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]|[a-zA-Z0-9])(\.([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]|[a-zA-Z0-9]))(\.([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]|[a-zA-Z0-9]))*$/;
      let sepPos: number = 0;
      let localPart: string;
      let domain: string;
      let localPartOk: boolean = false;
      let domainOk: boolean = false;
      sepPos = s.lastIndexOf("@");
      localPart = s.substring(0, sepPos + 1);
      domain = s.substring(sepPos + 1, s.length);
      if (localPartfilter1.test(localPart))
        localPartOk = true;
      else if (localPartfilter2.test(localPart))
        localPartOk = true;
      else
        localPartOk = false;
      if (domainfilter.test(domain))
        domainOk = true;
      else
        domainOk = false;

      if (localPartOk != true || domainOk != true)
        return false;
      else
        return true;
    }
    return false;
  }
}
