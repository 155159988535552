<div class="info-page">
    <div class="title-bar">
        <span>FAQ</span>
    </div>

    <div class="padding-layout">
        <h1>COS’È DoxaeXpression?</h1>
        <p>
            DoxaeXpression è un’iniziativa di Doxa in collaborazione con MobileXpression. Obiettivo dell’iniziativa è rilevare informazioni sempre aggiornate sulle modalità di utilizzo degli smartphone, con lo scopo di migliorare la fruizione di nuovi siti e app per dispositivi mobili.
        </p>
        
        <h1>COME FUNZIONA?</h1>
        <p>
            Ti chiediamo di installare l’app di MobileXpression sul tuo dispositivo e di mantenerla installata. Se l’app rimarrà funzionante per almeno tre mesi, potrai richiedere il tuo primo premio. Successivamente ogni mese in cui l’app rimarrà funzionante per almeno 20 giorni, ti permetterà di richiedere un nuovo premio.
            Ci auguriamo che la tua partecipazione prosegua per tutta la durata dell’iniziativa. L’installazione dell’app non comporta costi aggiuntivi e non richiede di svolgere compiti onerosi o di cambiare le tue abitudini.
            Ti chiediamo solo di mantenere l’app installata e funzionante senza cambiare le modalità di utilizzo del tuo smartphone.
        </p>

        <h1>
            COSA CI GUADAGNO A PARTECIPARE?
        </h1>
        <p>
            Per ringraziarti della tua preziosa collaborazione, ti verrà attribuito un premio del valore di € 15 al termine dei primi tre mesi di partecipazione all’iniziativa. Per ogni mese successivo riceverai un premio di € 3. Dopo i primi tre mesi potrai richiedere un Buono Amazon.it* del valore dei premi accumulati da spendere per i tuoi acquisti online.
            Per avere diritto al premio, dovrai assicurarti che l’app risulti:
        </p>
        
        <ul>
            <li>Funzionante: per consentire la trasmissione quotidiana delle informazioni l’app deve essere sempre in esecuzione e il traffico dati Internet del tuo dispositivo deve essere sempre abilitato;</li>
            <li>Attiva: la tua app verrà considerata attiva mensilmente se la sua trasmissione dati risulterà corretta per almeno 20 giorni nell’arco del mese.</li>
        </ul>
        <p>*Restrizioni applicate. Vedere dettagli su: <a target="_blank" href="https://amazon.it/gc-legal">https://amazon.it/gc-legal</a></p>
        



        <h1>QUALI SONO I REQUISITI PER PARTECIPARE?</h1>
        <ul>
            <li>Aver compiuto 18 anni;</li>
            <li>Essere proprietari ed utilizzatori di uno smartphone con piano dati (preferibilmente “flat”);</li>
            <li>Essere proprietari ed utilizzatori di uno smartphone con sistema operativo Android o iOS (iPhone) o di un tablet iOS - iPad dotato di SIM;</li>
            <li>Aver installato una versione del sistema operativo Android o iOS sufficientemente aggiornata. Ti invitiamo a verificare i requisiti minimi a questo link: (riferimento al pdf)</li>
            <li>NON aver installato app che: (1) gestiscano la memoria del dispositivo eliminando file e bloccando i servizi in esecuzione (es. Clean Master, Ccleaner, ecc.) e (2) ottimizzino il consumo della batteria del proprio dispositivo (es. Battery Doctor, Battery Saver, ecc.);</li>
        </ul>
        
        <h1>COSA DEVO FARE PER PARTECIPARE?</h1>
        <p>
            Per considerare la propria partecipazione valida l’utente deve: 
        </p>
        <ul>
            <li>Compilare il questionario di registrazione in ogni sua parte;</li>
            <li>Inserire un numero di cellulare e un indirizzo e-mail corretti e validi</li>
            <li>Installare l’app sul proprio dispositivo;</li>
            <li>Qualora sia necessario per il suo smartphone, abilitare la funzionalità «Accesso ai dati» per l’app;</li>
            <li>Mantenere la connessione dati attiva e funzionante;</li>
            <li>Verificare che NON ci siano impostazioni che impediscano all’app di trasmettere dati.</li>
        </ul>

        <h1>COME POSSO RICHIEDERE UN PREMIO?</h1>
        <p>
            Inserisci il tuo numero di cellulare e la tua mail in Homepage nell’apposita sezione, e clicca il pulsante “Richiedi Premio”. Tutti i tuoi premi mensili accumulati verranno convertiti in un Buono Amazon.it* del valore corrispondente.
        </p> 
        <p>*Restrizioni applicate. Vedere dettagli su: <a target="_blank" href="https://amazon.it/gc-legal">https://amazon.it/gc-legal</a></p>



        <h1>HO FATTO LA RICHIESTA. QUANDO RICEVERÒ IL PREMIO?</h1>
        <p>
            I premi vengono erogati con cadenza mensile per cui, nel peggiore dei casi, aspetterai circa 30  giorni per ricevere il tuo Buono.
        </p>
        
        <h1>COME AVVIENE L’EROGAZIONE DEL BUONO?</h1>
        <p>
            Il Buono Amazon viene erogato tramite e-mail entro 30 giorni dalla richiesta. Il codice del tuo Buono può essere inserito anche quando gli ordini sono "alla cassa". La durata è di circa 10 anni.
        </p>
        
        <h1>
            QUANTO DURA L’INIZIATIVA?
        </h1>
        <p>L’iniziativa ha una durata di 12 mesi con possibilità di rinnovo automatico annuale.</p>

        <h1>POSSO CANCELLARMI DA DoxaeXpression?</h1>
        <p>
            Certamente! Puoi cancellarti dall’iniziativa in ogni momento. È sufficiente mandare una mail a <a href="mailto:doxaexpression@doxa.it">doxaexpression@doxa.it</a> con oggetto “Cancella Iscrizione”. Ti ricordiamo che dovrai comunque disinstallare autonomamente l’app dal tuo cellulare.
        </p>

    </div>
</div>