import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-cookie',
  templateUrl: './privacy-cookie.component.html',
  styleUrls: ['./privacy-cookie.component.scss']
})
export class PrivacyCookieComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
