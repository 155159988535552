
<mat-toolbar color="#FFFFFF">
  <button
    type="button"
    aria-label="Toggle sidenav"
    mat-icon-button
    (click)="toggleDrawer()"
    *ngIf="isHandset$ | async">
    <mat-icon aria-label="Side nav toggle icon" *ngIf="!showMobileMenu">menu</mat-icon>
    <mat-icon aria-label="Side nav toggle icon" *ngIf="showMobileMenu">close</mat-icon>
  </button>
  
  <div id="app-header" fxLayout="row" fxLayoutAlign="start">

    <div fxFlex.xs="100" fxFlex.sm="100" fxHide.md fxHide.lg fxHide.xl>
      <img src="/assets/images/header_logo.png" width="90%">
    </div>

    <div fxHide.xs fxHide.sm fxFlex.md="40">
      <img id="desktop-logo-image" src="/assets/images/header_logo.png">
    </div>
    
    <div fxFlex.md="60" fxFlex.lg="60" fxFlex.xl="60" class="separator" fxHide.xs fxHide.sm fxFlexAlign.md="end">
      <div id="header-menu">
        <div><a [routerLink]="['/']">Home</a></div>
        <div><a [routerLink]="['/faq']">FAQ</a></div>
      </div>
    </div>

  </div>
</mat-toolbar>
<!-- <ngx-cookiebot-declaration></ngx-cookiebot-declaration> -->
<app-menu [isMenuVisible]="showMobileMenu" (menuEventCloseEmitter)="toggleDrawer()"></app-menu>