<div class="info-page">
    <div class="title-bar">
        <span>Privacy e Cookie Policy</span>
    </div>
    
    <div class="padding-layout">

        <h1>Informativa ex Regolamento UE n° 679/2016, artt. 13 e 14</h1>
        <p>
            Ai sensi e per gli effetti di quanto disposto dal Regolamento UE n° 679/2016, in materia di protezione dei dati personali, Vi informiamo che Doxa S.p.A. detiene e verrà in possesso di dati a Voi relativi in relazione ai rapporti intercorrenti tra Voi e la medesima per l’iniziativa DoxaeXpression.
        </p>
        <p>
            Tali dati sono e verranno trattati – mediante l’utilizzo di mezzi elettronici automatizzati e/o anche manualmente – a norma di legge, secondo i principi stabiliti dall’art. 5 del Regolamento citato, in modo lecito, corretto e trasparente, in modo da tutelare appieno la riservatezza ed i diritti a Voi riconosciuti.
        </p>
        <p>
            Per quanto riguarda le finalità del trattamento, i Vostri dati saranno utilizzati esclusivamente per gli adempimenti derivanti da obblighi normativi e contrattuali connessi alla Vostra partecipazione all’iniziativa e verranno conservati esclusivamente per tali finalità e non oltre il tempo richiesto dalle norme di legge.
        </p>
        <p>
            Potranno venire a conoscenza dei Vostri dati le seguenti categorie di incaricati: personale professionale, personale dei servizi di supporto, personale dei servizi amministrativi.
            Vi informiamo che il conferimento dei dati necessari all’adempimento di obblighi normativi e/o contrattuali è obbligatorio ed, in mancanza, non potremo dare corso ai nostri servizi. 
            I Vostri dati potranno essere comunicati, per le finalità indicate, tra l’altro, a: chiunque sia legittimo destinatario di comunicazioni previste da norme di legge, regolamentari e contrattuali (quali, ad esempio: uffici ed Autorità Pubbliche, Banche, Compagnie di assicurazione, ecc.), società di gestione di servizi amministrativi, altre società delle quali Doxa si avvalga per adempiere ai propri obblighi legali o contrattuali (quali, ad esempio: partner per l’erogazione di premi e incentivi, ecc.), consulenti della nostra società e società del network Doxa, debitamente incaricati come responsabili del trattamento, ove previsto, a norma di legge.
        </p>
        <p>
            In considerazione dell’esistenza di collegamenti telematici informatici o di corrispondenza con i soggetti suindicati i Vostri dati potranno essere trasferiti all’estero, anche al di fuori dell’UE, previa verifica del rispetto delle previsioni normative del Regolamento citato.
            Potrete sempre rivolgerVi a Doxa S.p.A., Via Panizza, 7 20144 Milano (mail: <a href="mailto:privacy@bva-doxa.com">privacy@bva-doxa.com</a>, n. fax +39 02-48193286), titolare del trattamento e al responsabile per la protezione dei dati, per far valere i Vostri diritti come previsti dagli articoli 15 (Accesso), 16 (Rettifica), 17 (Cancellazione), 18 (Limitazione al trattamento dei dati), 19 (Notifica avvenuta rettifica o cancellazione), 20 (Portabilità dati), 21 (Opposizione), 22 (Opposizione a trattamenti decisionali automatizzati) e 34 (Comunicazione avvenuta violazione dei dati personali) del suddetto Regolamento UE n° 679/2016. Vi informiamo, inoltre, che potete presentare reclamo all’Autorità Garante per la Privacy.
        </p>

        <h1>Gestione dei “cookies”</h1>
        <p>
            In ottemperanza alla normativa sulla privacy e secondo quanto disposto dal D.Lgs. 69/2012 e dalle “Linee guida cookie e altri strumenti di tracciamento – 10 giugno 2021” emanate dal Garante Privacy, definendo come “cookies” i file di testo che un sito web invia al browser del computer dell’utente, Vi informiamo che sul sito <a href="www.doxaexpression.it">www.doxaexpression.it</a> di Doxa S.p.A. sono presenti dei “cookies di sessione”, che non vengono memorizzati in modo permanente sul computer dell’utente e vengono eliminati con la chiusura del browser con il fine di consentire all’utente stesso una migliore fruibilità del sito.
        </p>
        <p>
            La maggior parte dei browser accetta automaticamente i cookie dai siti web. Tuttavia, selezionando alcune opzioni tra le impostazioni del browser, l’utente può decidere di rifiutare tutti i “cookies” o alcuni di essi. Si precisa anche che, qualora l’utente decidesse di impostare la specifica funzione del browser che consente di rifiutare automaticamente tutti i “cookies”, potrebbe non essere possibile accedere a tutti i servizi disponibili sul sito.
        </p>

    </div>
</div>