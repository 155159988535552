<div class="info-page">
    <div class="title-bar">
        <span>Condizioni di partecipazione</span>
    </div>

    <div class="padding-layout">

        <h1>PREMESSA</h1>
        <br>
        <p>
            L’indagine DoxaeXpression è una iniziativa di MobileXpression (una divisione di VoiceFive, Inc., società di diritto statunitense con sede in 11950 Democracy Dr, Ste 600, Reston, Virginia, 20190, Stati Uniti, interamente controllata da comScore, Inc.) in collaborazione con Doxa S.p.a. (C.F. 00935420158, con sede in 20144 – Milano, Via Panizza 7).
        </p>
        
        <ol>
            <li>
                L’effettiva partecipazione all’indagine DoxaeXpression si intenderà perfezionata esclusivamente alle seguenti condizioni concorrenti:
                <ol>
                    <li>Registrazione da parte dell'Utente sul sito di MobileXpression, con creazione di un relativo account attraverso la compilazione del questionario di iscrizione;</li>
                    <li>Accettazione da parte dell'Utente delle presenti Condizioni di Partecipazione, dei Termini di Servizio di MobileXpression e dei termini e delle condizioni di Disposizioni sulla Privacy di MobileXpression, del Contratto di licenza dell’Utente di MobileXpression e della Comunicazione del brevetto di MobileXpression;</li>
                    <li>Accettazione da parte dell’Utente di ricevere periodicamente e-mail di aggiornamento circa l’iniziativa da parte di DoxaeXpression e della possibilità che il proprio operatore possa addebitare supplementi relativamente alla trasmissione di dati qualora l’Utente non sottoscrivesse alcun piano dati illimitato;</li>
                    <li>Corretto download dell’App di MobileXpression, che dovrà essere tenuta sempre in esecuzione sul dispositivo dell’Utente;</li>
                    <li>Ricevuta comunicazione da parte di DoxaeXpression dell’avvenuta iscrizione al Panel. Dal momento di ricezione della comunicazione, via e-mail, la creazione dell’account dell’Utente diverrà effettiva e, conseguentemente, verrà generato un “conto” individuale dell’account. L’Utente potrà accumulare i premi guadagnati a seguito della partecipazione attiva all’indagine all’interno del suddetto “conto”.</li>
                </ol>
            </li>
            <br>
            <li value="2">La partecipazione al Panel è completamente gratuita. L’Utente può cancellare l’applicazione e/o cancellarsi dal panel in qualsiasi momento, senza alcuna conseguenza. Completando l’iscrizione al Panel, si accettano tutte le Condizioni di Partecipazione qui esposte.</li>
        </ol>

        <br>

        <h1>PREMIO:</h1>
        <br>
        <ol>
            <li value="3">
                La partecipazione attiva al Panel dà diritto, qualora ne sussistano le condizioni, all’accumulo di un premio mensile nel “conto” associato all’account di ciascun Utente. Per avere diritto al premio mensile, la registrazione deve essere completa, e deve essere stato raggiunto il minimo di attività stabilito al punto 4. Anche in caso di cancellazione dal Panel, è possibile richiedere quanto accumulato fino al momento della cancellazione stessa, se e solo se si sia raggiunto il minimo di attività stabilito al punto 4.
                <br><br>
                L’Utente potrà richiedere il premio maturato fino a quel momento unicamente nel caso in cui avrà accumulato almeno 3 mesi di partecipazione attiva anche non consecutivi (vedi punto 5).
            </li>
            <br>
            <li>
                Ciascun utente ha diritto a un premio, accumulato mensilmente, se e solo se l’applicazione risulta:
                <ul>
                    <li>funzionante: l’applicazione deve sempre essere in esecuzione e il traffico dati Internet del dispositivo mobile deve sempre essere abilitato per consentire la trasmissione quotidiana delle informazioni;</li>
                    <li>attiva: la trasmissione dei dati deve risultare corretta per un minimo di 20 giorni nell’arco di un mese.</li>
                </ul>
            </li>
            <br>
            <li>Per i primi tre mesi di iscrizione, gli Utenti attivi, la cui applicazione rispetta quanto esposto nel punto 4, avranno diritto all’accumulo di 5 € mensili nel “conto” associato al proprio account. Al termine dei primi tre mesi di iscrizione e accumulati i 15 €, gli Utenti hanno diritto a richiedere l’erogazione del premio. A partire dal quarto mese di iscrizione, gli Utenti attivi, la cui applicazione rispetta quanto esposto nel punto 4, avranno diritto all’accumulo di 3 € mensili nel “conto” associato al proprio account. A partire dal quarto mese di iscrizione, il totale accumulato nel “conto” associato al proprio account (al netto di quanto già richiesto precedentemente) è richiedibile in qualsiasi momento.</li>            
            <br>
            <li>Per richiedere il premio accumulato nel “conto” associato al proprio account (al netto di quanto già richiesto precedentemente), ciascun Utente deve utilizzare l’apposita sezione “Richiedi premio” presente sul sito dell’iniziativa <a [routerLink]="['/']">www.doxaexpression.it</a>. Come indicato al punto 5, i premi possono essere richiesti a partire dall’inizio del quarto mese di attività. Ogni mese una e-mail proveniente dall’indirizzo doxaexpression@doxa.it informa tutti i Panelisti in merito all’ammontare di quanto accumulato sul “conto” associato al proprio account.</li>
            <br>
            <li>
                DoxaeXpression erogherà i premi richiesti e invierà questi via e-mail entro la fine del mese successivo dalla data in cui la richiesta è stata effettuata.
                <br><br>
                I premi attualmente constano in buoni regalo Amazon.it, il cui codice sarà inviato via e-mail dall’indirizzo doxaexpression@doxa.it all’indirizzo e-mail registrato sul sito DoxaeXpression al momento della richiesta. In caso l’indirizzo non sia valido, sarà cura dell’Utente avvisare all’indirizzo doxaexpression@doxa.it il cambio dell’indirizzo mail. Il buono regalo Amazon.it verrà nuovamente inviato al nuovo indirizzo e-mail solo se il primo invio non è stato effettivamente consegnato alla casella di posta (in presenza quindi di una mail di errore del sistema di posta che indica che l’indirizzo originale non sia più disponibile). MobileXpression si riserva la facoltà di variare la tipologia dei premi oggetto dell’iniziativa. 
            </li>
            <br>
            <li>
                Le segnalazioni inerenti al mancato ricevimento dei premi richiesti devono pervenire all’indirizzo e-mail doxaexpression@doxa.it entro 90 giorni dalla data in cui la richiesta è stata effettuata. Dopo tale termine, eventuali segnalazioni non verranno prese in considerazione e nulla sarà più dovuto all’Utente in merito alla richiesta stessa.
            </li>
            <br>
            <li>
                Attualmente, il progetto termina il 31 Dicembre 2022. MobileXpression avrà facoltà di rinnovare automaticamente l’iniziativa. Resta salvo il diritto dell’Utente di cancellarsi in qualsiasi momento e la facoltà di MobileXpression di cancellare l’iniziativa in qualsiasi momento. 
                
                Al termine del progetto, o qualora il progetto dovesse terminare prima della data indicata, verranno concessi 60 giorni di tempo dalla comunicazione di chiusura dell’iniziativa per effettuare le richieste premio del credito accumulato fino a quel momento (al netto di quanto già richiesto precedentemente). Qualora l’Utente dovesse decidere di cancellarsi dal Panel, la richiesta del premio accumulato fino a quel momento (al netto di quanto già richiesto precedentemente) deve essere effettuata prima di concludere l’operazione di cancellazione. Una volta compiuta tale operazione, non sarà più possibile richiedere quanto accumulato. 
            </li>
            <br>
            <li>
                Pur agendo in modo da minimizzare l'eventuale accadimento di problemi, DoxaeXpression non si assume alcuna responsabilità per mancate ricezioni di quanto richiesto, dovute a errori, malfunzionamenti, problemi tecnici di software, degli strumenti utilizzati o di qualsiasi altra causa.
            </li>
            <br>
            <li>
                Come indicato al punto 4, l’unico dato valido per valutare l’attività giornaliera/settimanale/mensile al fine dell’accumulo dei premi nei “conti” associati agli account degli Utenti è quello proveniente direttamente dal server che raccoglie i dati provenienti dalle applicazioni di MobileXpression installate sui dispositivi mobili degli Utenti stessi. Perciò, eventuali segnalazioni da parte di Utenti in merito ad attività effettuate mediante il proprio dispositivo mobile, ma non presenti nei dati raccolti dal server, non potranno e non saranno prese in considerazione.
            </li>
        </ol>

        <br>

        <h1>REQUISITI:</h1>
        <br>
        <ol>
            <li value="12">È consentita l'iscrizione a Doxaexpression a tutte le persone:
                <ul>
                    <li>che abbiano compiuto 18 anni;</li>
                    <li>proprietarie ed utilizzatrici di uno smartphone con piano dati (preferibilmente “flat”);</li>
                    <li>proprietarie ed utilizzatrici di uno smartphone con sistema operativo Android o iOS (iPhone) o possessori di iPad dotati di SIM;</li>
                    <li>che non abbiano installato App che: (1) gestiscano la memoria del dispositivo eliminando file e bloccando applicazioni, e (2) ottimizzino il consumo della batteria del proprio dispositivo. A mero titolo di esempio e non esaustivo non sono compatibili APP come Clean Master, Ccleaner,  Battery Doctor, Battery Saver.• che inseriscano nel questionario di iscrizione il numero di cellulare del dispositivo dove è installata l’applicazione e un indirizzo e-mail valido.</li>
                </ul>
                L’elenco delle dotazioni minime compatibili per l’utilizzo dell’APP MobileXpression sono in evoluzione. L’elenco delle caratteristiche dei dispositivi attualmente compatibili è presente sul sito a questo <a [routerLink]="['/faq']">link</a>
            </li>
            <br>
            <li>
                L’indirizzo specificato al momento dell’iscrizione al Panel è quello presso cui verrà ricevuta una e-mail di conferma dell’iscrizione. Come indicato al punto 1.5., l’iscrizione risulta effettivamente completata solo quando si riceve tale e-mail.
            </li>
            <br>
            <li>Per considerare la propria partecipazione attiva, ciascun Utente deve assicurarsi che:
                <ul>
                    <li>il questionario di registrazione sia stato completato in ogni sua parte;</li>
                    <li>l’applicazione di MobileXpression sia stata correttamente installata sul proprio dispositivo mobile;</li>
                    <li>sia stata abilitata la funzionalità «Accesso ai dati» per l’applicazione (solo Android 5 o superiore);</li>
                    <li>la connessione dati risulti attiva e funzionante;</li>
                    <li>non ci siano impostazioni che impediscono all’app di MobileXpression di trasmettere dati.</li>
                </ul>
            </li>
            <br>
            <li>
                DoxaeXpression non si ritiene responsabile di qualsiasi caratteristica di produzione e/o dipendente dall’Utente del dispositivo mobile mediante il quale si intende partecipare al Panel a causa della quale il dispositivo stesso non sia in grado di trasmettere dati. In tal caso, la partecipazione non può ritenersi attiva, e nessun premio sarà dovuto all’Utente. In caso di dubbi in merito alla compatibilità dell’applicazione con il proprio dispositivo mobile, si invita a scrivere all’indirizzo e-mail <a href="mailto:doxaexpression@doxa.it">doxaexpression@doxa.it</a> indicando: marca e modello del dispositivo mobile e la versione del suo sistema operativo.
            </li>
            <br>
            <li>Al momento dell’iscrizione, l’Utente si impegna a non adottare alcun comportamento che possa arrecare danno agli interessi di Doxa, di MobileXpression e al progetto DoxaeXpression. In particolare, si precisa che ogni persona singola:
                <ul>
                    <li>
                        può effettuare una sola iscrizione all’indagine, e in caso di iscrizioni multiple tutti gli account individuati verranno cancellati con conseguente perdita del premio accumulato fino a quel momento;
                    </li>
                    <li>
                        deve inserire dati veritieri ed aggiornati al momento dell’iscrizione rispondendo al relativo questionario (la veridicità di tali dati sarà verificata sia al momento dell’iscrizione, sia in seguito, e potrà portare, in caso di esito negativo di tali verifiche, alla sospensione o cancellazione dell’account, con relativa perdita dei premi accumulati fino a quel momento);
                    </li>
                    <li>
                        deve segnalare, mediante i riferimenti e recapiti forniti nella sezione “Richiesta informazioni” del sito <a [routerLink]="['/']">www.doxaexpression.it</a>, qualsivoglia modifica e/o cambiamenti nei propri dati, in particolare inerenti a numero di cellulare, indirizzo e-mail, trasferimenti prolungati o definitivi in località diverse da quella indicata precedentemente come domicilio;
                    </li>
                    <li>
                        deve mantenere registrato un indirizzo e-mail attivo, personale (non condiviso con altre persone, membri del proprio nucleo familiare o esterni a esso) e consultato frequentemente;
                    </li>
                    <li>
                        non può cedere, anche temporaneamente, a nessun altro individuo il proprio account;
                    </li>
                    <li>
                        non può trasferire/scambiare/cedere il premio accumulato nel “conto” associato al proprio account verso altri il “conto” o i “conti” di altri iscritti al Panel;
                    </li>
                    <li>
                        non può richiedere in forma di denaro il premio accumulato tramite la partecipazione all’iniziativa.
                    </li>
                </ul>
            </li>
            <br>
            <li>
                In caso di periodi di inattività superiori ai tre mesi (cioè, in caso di assenza di trasmissione di dati attiva superiore ai tre mesi), anche non consecutivi, l’iscrizione a DoxaeXpression potrà essere sospesa e, infine, cancellata a discrezione dello staff dell’iniziativa. Lo stesso staff avviserà via e-mail, presso l’indirizzo indicato in fase di iscrizione, dello stato di sospensione e, in seguito, di imminente/avvenuta cancellazione dell’account, sollecitando una verifica dello stato di attività da parte dell’Utente.
            </li>
            <br>
            <li>
                Con l’accettazione delle presenti Condizioni di Partecipazione l’Utente dichiara di conoscere e accettare che l’indagine DoxaeXpression è una iniziativa di MobileXpression (una divisione di VoiceFive, Inc., società di diritto statunitense con sede in 11950 Democracy Dr, Ste 600, Reston, Virginia, 20190, Stati Uniti, interamente controllata da comScore, Inc.), la quale è l’unico soggetto titolare e responsabile dell’iniziativa stessa, dell’applicazione MobileXpression e di tutte le attività relative alla raccolta e al trattamento dei dati degli Utenti, nei termini e alle condizioni indicati nei Termini di Servizio e nell’Informativa sulla Privacy di MobileXpression che verranno forniti all’Utente in sede di iscrizione. Doxa pertanto non sarà responsabile per eventuali perdite o danni causati dalle attività poste in essere da MobileXpression.  
            </li>

        </ol>

    </div>
</div>
