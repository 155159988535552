import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  public showMobileMenu = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 960px)'])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver) {
    
  }

  toggleDrawer() {
    this.showMobileMenu = this.showMobileMenu ? false : true;
  }
}
