import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input()
  public isMenuVisible: boolean;

  @Output()
  public menuEventCloseEmitter = new EventEmitter<boolean>();

  constructor() {
    this.isMenuVisible = false; 
  }

  ngOnInit(): void {
  }

  hide() {
    this.isMenuVisible = false; 
    this.menuEventCloseEmitter.emit(this.isMenuVisible);
  }

  // emitClose(value: string) {
    
  // }
}
