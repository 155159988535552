<div fxLayout='column' fxLayoutAlign="start stretch" class="column-layout">

  <div fxFlex="100" id="title-box-index" class="padding-layout" fxLayout.xs='column' fxLayout.sm='column' fxLayout.md="row"
    fxLayoutAlign="end">

    <div fxFlex.xs="100" fxFlex.sm="100" fxFlexOrder.xs="2" fxFlexOrder.sm="2" fxFlexOrder.md="1" class="box-text" id="main-title-intro-text">

      <div fxHide.md fxHide.xl fxHide.lg class="h1-app text-align-center">
        Vuoi partecipare alla nuova indagine DoxaeXpression?<br>Da oggi anche con iPhone e iPad
      </div>

      <div fxHide.xs fxHide.sm class="h1-app text-align-left">
        Vuoi partecipare alla nuova indagine DoxaeXpression?<br>Da oggi anche con iPhone e iPad
      </div>

      <a id="register-btn" href="http://www.mobilexpression.com/signup.aspx?pg=doxait">
        <button mat-flat-button color="primary" id="register" class="button-app">ISCRIVITI</button>
      </a>

      <div class="text-body-1">Sei già iscritto? Scegli il dispositivo</div>

      <div fxLayout.xs='column' fxLayout.sm='column' fxLayout.md="row" class="store-images-container" style="padding-top: 10px; padding-bottom: 30px;">

        <div fxHide.xs fxHide.sm fxLayout="row" class="store-images">
          <a target="_blank" fxFlex.md="30" href="https://play.google.com/store/apps/details?id=com.mobilexpression.ltvpnb"><img width="100%" src="/assets/images/google_play.png"></a>
          <a target="_blank" fxFlex.md="30" style="padding-left: 10px;" href="https://www.mobilexpression.com/login" ><img width="100%" src="/assets/images/app_store.png"></a>
        </div>

        <div fxHide.md fxHide.lg fxHide.xl fxLayout.xs='column' fxLayout.sm='column' class="store-images">
          <a target="_blank" fxFlex.xs="100" fxFlex.sm="100" href="https://play.google.com/store/apps/details?id=com.mobilexpression.ltvpnb"><img src="/assets/images/google_play.png" width="60%" style="padding-top: 0;"></a>
          <a target="_blank" fxFlex.xs="100" fxFlex.sm="100" href="https://www.mobilexpression.com/login"><img src="/assets/images/app_store.png" width="60%" style="padding-top: 0;"></a>
        </div>

      </div>
    </div>

    <div style="padding-top: 10px;" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="30" fxFlexOrder.xs="1" fxFlexOrder.sm="1" fxFlexOrder.md="2">
      <img id="gift-cards-img" src="/assets/images/gift_boxes.png">
    </div>

  </div>

  <div fxFlex="100" fxLayout.xs='column' fxLayout.sm='column' fxLayout.md="row" fxLayoutAlign="center" id="form-container">

    <div fxFlex.xs="100" fxFlex.md="50" id="prize-box1-index" class="text-align-left padding-layout">

      <div class="h1-app">
        Hai già maturato il tuo premio? Compila il form sottostante
      </div>

      <div class="text-body-2" id="text-body-2-rule">
        Per richiedere il premio è necessario inserire lo stesso numero di telefono e indirizzo e-mail con cui ti sei
        registrato all'indagine DoxaeXpression
      </div>

      <div class="text-body-2 text-underlined text-uppercase text-bold" style="padding-bottom: 30px;">
        Attenzione! I campi sottostanti sono riservati a chi ha già accumulato i primi 3 buoni
      </div>

      <form class="prize-form">
        <mat-form-field floatLabel="always" class="example-full-width" appearance="outline">
          <mat-label><span class="text-bold">Numero di telefono</span> (obbligatorio)</mat-label>
          <input matInput value="" color="white" [(ngModel)]="phone" [ngModelOptions]="{standalone: true}">
        </mat-form-field>

        <!-- <div id="phone-number-hint" class="text-body-3 text-bold">
          Inserisci il numero con il prefisso senza altri caratteri; es: 3331234567
        </div> -->

        <mat-form-field floatLabel="always" class="example-full-width" appearance="outline">
          <mat-label><span class="text-bold">Indirizzo e-mail</span> (obbligatorio)</mat-label>
          <input matInput [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </form>

      <div class="request-privacy" fxLayout='row' fxLayoutGap="4px">
        <div fxFlex="5%">
          <mat-checkbox class="example-margin" [(ngModel)]="prizePrivacy" [ngModelOptions]="{standalone: true}"></mat-checkbox>
        </div>
        <div fxFlex="95%">Accetto il trattamento dei dati secondo l’<span class="text-bold"><a class="remove-underlined-link" [routerLink]="['/privacy-cookie']">informativa privacy</a></span> di Doxa</div>
      </div>

      <div style="text-align: center;" fxHide.sm fxHide.md fxHide.lg fxHide.xl>
        <button mat-flat-button color="primary" id="request-prize" class="button-app" (click)="requestPrize()">RICHIEDI PREMIO</button>
      </div>

      <div style="text-align: right;" fxHide.xs>
        <button mat-flat-button color="primary" id="request-prize-desktop" class="button-app" (click)="requestPrize()">RICHIEDI PREMIO</button>
      </div>

      <div class="hint-container" fxLayout='row' fxLayoutGap="4px">
        <a [routerLink]="['/faq']" style="text-decoration: none;">
          <div fxFlex="14%"><img width="100%" class="icon" src="/assets/images/info-icon.png"></div>
          <div class="hint-text" fxFlex="85%">Per maggiori informazioni sull’iniziativa ti invitiamo a leggere le FAQ
          </div>
        </a>
      </div>

      <div class="hint-container" fxLayout='row' fxLayoutGap="4px">
        <a target="_blank"
          href="https://files.dpzresearch.it/panel/deXpS/DoxaeXpression_Istruzioni_Installazione_2022.pdf"
          style="text-decoration: none;">
          <div fxFlex="15%"><img class="icon" src="/assets/images/instructions-icon.png"></div>
          <div class="hint-text" fxFlex="85%">Leggi le istruzioni di installazione dell'app DoxaeXpression</div>
        </a>
      </div>

    </div>

    <mat-divider [vertical]="isHandset$ | async" class="divider"></mat-divider>

    <div fxFlex.xs="100" fxFlex.md="50" id="prize-box2-index" class="text-align-left padding-layout">

      <div class="h1-app">
        Richiesta informazioni
      </div>

      <div class="text-body" style="padding-bottom: 40px;">
        Per qualsiasi richiesta che riguarda l'indagine DoxaeXpression utilizza il form qui di seguito. Riceverai una
        risposta entro 1-2 giorni lavorativi.
      </div>

      <form class="info-form">
        <mat-form-field floatLabel="always" class="example-full-width" appearance="outline">
          <mat-label><span class="text-bold">Indirizzo e-mail</span> (obbligatorio)</mat-label>
          <input matInput value="" [(ngModel)]="infoEmail" [ngModelOptions]="{standalone: true}">
        </mat-form-field>

        <mat-form-field floatLabel="always" class="example-full-width" appearance="outline">
          <mat-label><span class="text-bold">Numero di telefono</span> (obbligatorio)</mat-label>
          <input matInput [(ngModel)]="infoPhone" [ngModelOptions]="{standalone: true}">
        </mat-form-field>

        <mat-form-field floatLabel="always" class="example-full-width" appearance="outline">
          <mat-label><span class="text-bold">Argomento</span></mat-label>
          <select matNativeControl required [(ngModel)]="infoSubject" [ngModelOptions]="{standalone: true}">
            <option value="">Seleziona l'argomento</option>
            <option value="Dubbi e domande sull'iniziativa">Dubbi e domande sull'iniziativa</option>
            <option value="Problemi di installazione dell'App DoxaeXpression">Problemi di installazione dell'App
              DoxaeXpression</option>
            <option value="Problemi di iscrizione all'iniziativa">Problemi di iscrizione all'iniziativa</option>
            <option value="Dubbi, domande e problemi riguardanti i premi">Dubbi, domande e problemi riguardanti i premi
            </option>
            <option value="Problemi di accesso alla propria area personale">Problemi di accesso alla propria area
              personale</option>
            <option value="Altro">Altro</option>
          </select>
        </mat-form-field>

        <mat-form-field floatLabel="always" class="example-full-width" appearance="outline">
          <mat-label><span class="text-bold">Messaggio</span></mat-label>
          <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10"
            [(ngModel)]="infoMessage" [ngModelOptions]="{standalone: true}"></textarea>
        </mat-form-field>
      </form>

      <div class="request-privacy" fxLayout='row' fxLayoutGap="4px">
        <div fxFlex="5%">
          <mat-checkbox class="example-margin" [(ngModel)]="infoPrivacy" [ngModelOptions]="{standalone: true}"></mat-checkbox>
        </div>
        <div fxFlex="95%">Accetto il trattamento dei dati secondo l’<span class="text-bold"><a class="remove-underlined-link" [routerLink]="['/privacy-cookie']">informativa privacy</a></span> di Doxa</div>
      </div>

      <div style="text-align: center;" fxHide.sm fxHide.md fxHide.lg fxHide.xl>
        <button mat-flat-button color="primary" id="request-info" class="button-app" (click)="requestInfo()">INVIA RICHIESTA</button>
      </div>

      <div style="text-align: right;" fxHide.xs>
        <button mat-flat-button color="primary" id="request-info-desktop" class="button-app" (click)="requestInfo()">INVIA RICHIESTA</button>
      </div>

    </div>

  </div>

</div>